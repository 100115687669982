import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar.jsx";
import Footer from "components/Footer.jsx";
// import { IconName } from "react-icons/io5";
// Images
import travlee from 'assets/img/Travlee.png'
// import RoutePlanningImg from 'assets/img/Route_planning.jpg'
import ResultsScreenImg from 'assets/img/screens/results.png'
import TreePlantingImg from 'assets/img/screens/planting.png'
import LandingImg from 'assets/img/screens/landing.png'
import ProfileImg from 'assets/img/screens/profile.png'
import CarbonCycleImage from 'assets/img/tree-lifecycle.png'
import PlayIcon from 'assets/img/playstore.png'
import AppStoreIcon from 'assets/img/appstore.png'
// Icons
import { MdTrain } from "react-icons/md";
import { FaQuestion, FaTree, FaChartLine } from "react-icons/fa";
import { FiDownloadCloud } from "react-icons/fi";
import { TreeCountryChart } from 'components/TreeCountryChart.jsx'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";

export const Landing = (props) => {
  const [emailSendComplete, setEmailSendComplete] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [valid, formValid] = useState(false)

  const scrollToElement = (name, offset) => {
    const elm = document.getElementById(name);
    var elemPos = elm ? elm.getBoundingClientRect().top + window.pageYOffset : 0;

    window.scroll({ top: elemPos - offset, left: 0, behavior: 'smooth' });
  }

  const contactFormSubmit = async () => {
    setSendingEmail(true)

    const body = {
      name: name,
      from: email,
      message: message
    }

    const resp = await axios.post("https://travlee-mailer.herokuapp.com/api/email/send", body)

    if (resp.status === 200) {
      setEmailSendComplete(true)
    }

    setSendingEmail(false)
  }

  return (
    <>
      <Navbar
        navItemClicked={scrollToElement}
      />

      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh"
          }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover bg-gradient-to-r from-header to-icon"
          // style={{
          //   backgroundImage: "url('https://images.pexels.com/photos/615348/forest-fog-sunny-nature-615348.jpeg?cs=srgb&dl=pexels-skitterphoto-615348.jpg&fm=jpg')"
          // }}
          >
            {/* <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span> */}
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full px-4 ml-auto text-center">
                <div className="text-white text-center inline-flex items-center justify-center rounded-full w-32 mb-10 shadow-lg bg-grey-300">
                  <img
                    alt="..."
                    src={travlee}
                    className="align-middle w-full"
                  />
                </div>
                <h1 className="text-white font-semibold text-5xl">
                  Travel with the <span className="text-green bold">environment</span> in mind
                </h1>
                <p className="mt-4 text-lg text-gray-300">
                  Plan more environmentally friendly journeys around the UK and help offset emissions by planting trees!
                </p>
                {/* <div className="relative flex flex-col min-w-0 break-words bg-white w-104 mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-md"
                  />
                </div> */}
                <div class="mt-10 md:w-5/12 mx-auto px-10 flex">
                  <a href="https://play.google.com/store/apps/details?id=com.tavlee.journey" target="_blank" rel="noreferrer" class="md:px-4">
                    <img alt="..." src={PlayIcon} width="200" />
                  </a>
                  <a href="https://apps.apple.com/app/id1558675392" target="_blank" rel="noreferrer" class="md:px-4">
                    <img alt="..." src={AppStoreIcon} width="200" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-secondary">
                      {/* <i className="fas fa-award"></i> */}
                      <MdTrain size={20} />
                    </div>
                    <h6 className="text-xl font-semibold">Plan your trip</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Find the best way of getting from A-to-B by different means of transport
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-third">
                      {/* <i className="fas fa-retweet"></i> */}
                      <FiDownloadCloud size={20} />
                    </div>
                    <h6 className="text-xl font-semibold">
                      Reduce Carbon Emissions
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Compare different routes and see which is the most environmentally friendly
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-icon">
                      {/* <i className="fas fa-fingerprint"></i> */}
                      <FaTree size={20} />
                    </div>
                    <h6 className="text-xl font-semibold">
                      Plant trees
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Help to offset carbon emissions generated by the journey by planting trees!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32" id="about">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mb-10">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-icon">
                  {/* <i className="fas fa-user-friends text-xl"></i> */}
                  {/* <FaQuestion size={20} /> */}
                  {/* <div className="text-white text-center inline-flex items-center justify-center rounded-full w-24 h-16 mb-10 shadow-lg bg-grey-300"> */}
                  <FaQuestion size={20} />
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  What is Travlee?
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Travlee is a journey planning app for trips around the UK,
                  that provides information on carbon emissions for each trip.
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  You can then offset the emissions generated by the journey by planting trees
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  We are currently generating far more carbon emissions than the trees we have are able to offset
                  so increasing the amount of woodlands we have is a step in the right direction
                </p>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Woodlands in the UK only cover 13% of the land area, far less than most other developed nations
                </p>
                <p className="text-xl font-light leading-relaxed mt-4 mb-4 text-green font-bold">
                  We are on a mission to change this!
                </p>
                {/* <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  The kit comes with three pre-built pages to help you get
                  started faster. You can change the text and images and
                  you're good to go. Just make sure you enable them first via
                  JavaScript.
                </p>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                  className="font-bold text-gray-800 mt-8"
                >
                  Check Tailwind Starter Kit!
                </a> */}
              </div>

              <div className="w-10/12 sm:w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full transform rotate-6"
                  src={LandingImg}
                />
              </div>

            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-10/12 sm:w-full md:w-4/12 ml-auto mr-auto px-4 mb-20">
                <img
                  alt="..."
                  className="max-w-full transform -rotate-6"
                  src={ResultsScreenImg}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-icon">
                    {/* <i className="fas fa-rocket text-xl"></i> */}
                    <MdTrain size={30} />
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Greener route planning
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Plan your trips around how much they will impact the environment
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Carbon emissions calculation for each journey
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Compare how much carbon is reduced compared to driving
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Live route information
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative bg-gray-300 py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="flex flex-wrap items-center mt-32">
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto text-right mb-20">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-icon">
                    <FaTree size={30} />
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Plant trees to offset emissions
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    After planning your journey and knowing how many emissions have been generated,
                    you can offset these emissions by planting trees with local conservation projects
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    While offsetting emissions may not be the perfect way to help the environment, having more trees in the world is a
                    big plus, for so many different reasons!
                  </p>
                  {/* <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    We support restoration projects within the UK to help reforistation within the country, the UK only has 15% of the country that is
                    natural forest and we'd like to improve that 
                  </p> */}
                </div>

                <div className="w-10/12 sm:w-full md:w-4/12 ml-auto mr-auto px-4">
                  <img
                    alt="..."
                    className="max-w-full transform rotate-6"
                    src={TreePlantingImg}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="flex flex-wrap items-center mt-32">
                <div className="w-10/12 sm:w-full md:w-4/12 ml-auto mr-auto px-4 mb-20">
                  <img
                    alt="..."
                    className="max-w-full transform -rotate-6"
                    src={ProfileImg}
                  />
                </div>
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto text-left">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-icon">
                    <FaChartLine size={30} />
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Track your progress
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Keep up-to-date on how many trees you have planted, emissions reduced compared to driving and how much carbon you have already offset
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    You can also plant more trees to offset even more emissions!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -- Tree planting section -- */}
        <section className="pb-6 relative block z-20" id="tree">
          <div className="absolute top-0 w-full h-full bg-center bg-cover -z-10"
            style={{
              backgroundImage: "url('https://images.pexels.com/photos/566496/pexels-photo-566496.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940')"
            }}
          >
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          {/* <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
              style={{ height: "80px", transform: "translateZ(0)" }}
            >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-green-600 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div> */}

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64 z-20">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4 mt-10">
                <h2 className="text-4xl font-semibold text-white">
                  Tree planting and conservation
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-200">
                  Supporting local reforestation project around the UK
                </p>
              </div>
            </div>
            <div className="items-center flex flex-wrap">
              <div className="w-full flex flex-wrap items-center mt-32">
                <div className="w-full md:w-4/12 ml-auto mr-auto bg-gray-200 rounded-lg p-4 mb-10">
                  <TreeCountryChart />
                </div>
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto text-left ">
                  <h3 className="text-3xl font-semibold text-white">
                    We need woodlands
                  </h3>
                  <p className="mt-8 text-lg leading-relaxed text-gray-200">
                    Woodland only accounts for 13% of the land area in the UK,
                    we are working with local projects to help imporve this
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-gray-200">
                    Planting the right trees is just as important, we only support the planting of native
                    and sustainable woodlands, whilst also working to preserve existing ones
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-gray-200">
                    100% of trees purchased goes directly to UK based projects
                  </p>
                </div>
              </div>
            </div>
            <div className="items-center flex flex-wrap">
              <div className="w-full flex flex-wrap items-center mt-32">
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto text-left">
                  <h3 className="text-3xl font-semibold text-white">
                    Trees help in so many ways
                  </h3>
                  <h4 className="mt-8 text-xl font-semibold text-white">
                    Biodiversity
                  </h4>
                  <p className="mt-2 text-lg leading-relaxed text-gray-200">
                    Trees significantly increase wildlife habitat and biodiversity, a single oak can support over 280 species
                  </p>
                  <h4 className="mt-4 text-xl font-semibold text-white">
                    Water
                  </h4>
                  <p className="mt-2 text-lg leading-relaxed text-gray-200">
                    They are very effective at cleaning, storing and regulating water supply, they are great assets for combatting flood risk
                  </p>
                  <h4 className="mt-4 text-xl font-semibold text-white">
                    Climate
                  </h4>
                  <p className="mt-2 text-lg leading-relaxed text-gray-200">
                    Highly effective at reducing carbon emissions in the ozone layer, helping to mitigate climate change
                  </p>
                </div>
                <div className="w-full md:w-4/12 ml-auto mr-auto p-2 rounded-lg shadow-lg bg-gray-200 mt-10">
                  <img
                    alt="..."
                    className="w-full"
                    src={CarbonCycleImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-6 relative block z-20" id="partners">
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64 z-20">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4 mt-10">
                <h2 className="text-4xl font-semibold">
                  Planting partners
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4">
                  Selected tree planting organisation around the UK that are imroving our natural habitats
                </p>
              </div>
              <div className="items-center flex flex-wrap">
                <div className="w-full flex flex-wrap items-center mt-28">
                  <div className="md:w-4/12 ml-auto mr-auto p-2">
                    <a href="https://treebourne.org/" target="_blank" rel="noreferrer">
                      <img
                        alt="..."
                        width="200"
                        src={"https://i.imgur.com/x0nsSgC.jpg"}
                      />
                    </a>
                  </div>
                  <div className="md:w-4/12 ml-auto mr-auto p-2">
                    <a href="https://www.makeitwild.co.uk/" target="_blank" rel="noreferrer">
                      <img
                        alt="..."
                        width="230"
                        src={"https://i.imgur.com/FS44P3t.png"}
                      />
                    </a>
                  </div>
                  <div className="md:w-4/12 ml-auto mr-auto p-2">
                    <a href="https://stumpupfortrees.org/" target="_blank" rel="noreferrer">
                      <img
                        alt="..."
                        width="600"
                        src={"https://stumpupfortrees.org/media/h4dliaox/stump-up-for-trees-logo.png"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -- Contact section -- */}
        <section className="pb-20 relative block bg-gray-900" id="contact">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4 mt-10 mb-10 sm:mt-0 sm:mb-0">
                <h2 className="text-4xl font-semibold text-white">
                  Got some questions?
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-2 text-gray-500">
                  Travlee is still in the development stages but please do get in touch
                </p>
                <p className="text-lg leading-relaxed mb-4 text-gray-500">
                  We'd love to hear from you!
                </p>
              </div>
            </div>
            {/* <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excelent Services
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Grow your market
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Launch time
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div> */}
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Fill in your details below
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                      We will get back to you as soon as possible!
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Full Name"
                        style={{ transition: "all .15s ease" }}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Email"
                        style={{ transition: "all .15s ease" }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Type a message..."
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    {emailSendComplete &&
                      <div className="relative w-full mb-3 bg-green rounded-lg p-5">
                        <label
                          className="block uppercase text-white text-xs font-bold"
                          htmlFor="message"
                        >Thank you for your email, we'll get back to you soon!</label>
                      </div>
                    }
                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => contactFormSubmit()}
                        disabled={emailSendComplete}
                      >
                        {sendingEmail ?
                          <CircularProgress color="light" style={{ width: '1.6rem', height: '1.6rem' }} />
                          :
                          "Send Message"
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

import React from "react";
import OtherNavbar from "components/navbars/OtherNavbar";

export const Terms = (props) => {
  return (
    <>
        <OtherNavbar />
        <main>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                minHeight: "3vh"
                }}>
            <div className="absolute top-0 w-full h-full bg-center bg-cover bg-gradient-to-r from-header to-icon">
                {/* <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span> */}
            </div>
            <div className="container relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full px-4 pt-12 ml-auto text-center">
                        <h1 className="text-white font-semibold text-5xl">
                            Terms and conditions
                        </h1>
                    </div>
                </div>
            </div>
            </div>
            <section className="relative py-20">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap">
                    <div className="w-full ml-auto mr-auto px-4">
                        <div className="md:pr-12">
                        <p className="mt-4 text-md leading-relaxed text-gray-400 font-light italic">
                            This document was last updated on February 12, 2021
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            These terms and conditions ("Agreement") set forth the general terms and conditions of your use of the "Travlee" mobile application ("Mobile Application" or "Service") and any of its related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and this Mobile Application developer ("Operator", "we", "us" or "our"). By accessing and using the Mobile Application and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Mobile Application and Services. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Mobile Application and Services.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            The Site provides the following services: We created a mobile app for a journey planning application for public transport journeys within the UK (Services). You agree that by accessing the Site and/or Services, you have read, understood, and agree to be bound by all of these Terms and Conditions
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            If you do not agree with all of these Terms and conditions, then you are prohibited from using the Site, Mobile Application and Services and you must discontinue use immediately. We recommend that you print a copy of these Terms of Service for future reference.  
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Accounts and membership</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You must be at least 18 years of age to use the Mobile Application and Services. By using the Mobile Application and Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Purchases</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            If you wish to purchase our carbon offsetting solutions for your journeys made available through the Website (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card and your billing address and information.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You represent and warrant that: (i) you have the legal right to use any card(s) or other payment methods (s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            We use Stripe third-party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            We reserve the right to refuse or cancel your order at any time for reasons including but not limited to product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Accuracy of information</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            Occasionally there may be information in the Mobile Application that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Mobile Application or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information in the Mobile Application including, without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Mobile Application should be taken to indicate that all information in the Mobile Application or Services has been modified or updated.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Advertisements</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            During your use of the Mobile Application and Services, you may enter into correspondence with or participate in promotions of advertisers or sponsors showing their goods or services through the Mobile Application and Services. Any such activity, and any terms, conditions, warranties or representations associated with such activity, is solely between you and the applicable third party. We shall have no liability, obligation or responsibility for any such correspondence, purchase or promotion between you and any such third party.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Links to other resources</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            Although the Mobile Application and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link in the Mobile Application and Services. Your linking to any other off-site resources is at your own risk.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Prohibited uses</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile Application and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Mobile Application and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Mobile Application and Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Mobile Application and Services for violating any of the prohibited uses.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Intellectual property rights</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            "Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by the Operator or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Operator. All trademarks, service marks, graphics and logos used in connection with the Mobile Application and Services, are trademarks or registered trademarks of the Operator or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Mobile Application and Services may be the trademarks of other third parties. Your use of the Mobile Application and Services grants you no right or license to reproduce or otherwise use any of the Operator or third-party trademarks.
                        </p>
                        <h2 className="mt-6 text-2xl font-semibold">Mobile Application License</h2>
                        <h3 className="mt-6 text-xl font-semibold">Use License</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            If you access the Site via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Terms and Conditions. 
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You shall not: 
                        </p>
                        <ul>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (3) violate any applicable laws, rules, or regulations in connection with your access or use of the application; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (5) use the application for any revenue-generating endeavour, commercial enterprise, or other purposes for which it is not designed or intended; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (6) make the application available over a network or other environment permitting access or use by multiple devices or users at the same time; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (7) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (8) use the application to send automated queries to any website or to send any unsolicited commercial e-mail; 
                            </li>
                            <li className="mt-4 text-lg leading-relaxed text-gray-600">
                                (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.
                            </li>
                        </ul>
                        <h3 className="mt-6 text-xl font-semibold">Apple and Android Devices</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play (each an “App Distributor”) to access the Site: 
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            (1) the license granted to you for our mobile application is limited to a non-transferable license to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; 
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            (2)  you must comply with applicable third-party terms of agreement when using the mobile application, e.g., if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the mobile application
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Submissions</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgement or compensation to you. 
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Disclaimer of warranty</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You agree that our Service is provided on an "as is" and "as available" basis and that your use of the Mobile Application and Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Limitation of liability</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            To the fullest extent permitted by applicable law, in no event will the Operator, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of the Operator and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one pound or any amounts actually paid in cash by you to the Operator for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Indemnification</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You agree to indemnify and hold the Operator and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Mobile Application and Services or any wilful misconduct on your part.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Severability</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Dispute resolution</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of United Kingdom without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of United Kingdom. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in the United Kingdom, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Communications</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            Visiting the Site, sending us emails, and completing online forms constitute electronic communications. For contractual purposes, you
                            (a) consent to receive communications from Travlee in an electronic form; and
                            (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Travlee provides to you electronically satisfy any legal obligation that such communications would satisfy if it were in a hard copywriting.
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a className="text-green" href="mailto:legal@travlee.uk">legal@travlee.uk</a>. 
                        </p>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Assignment</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the sale of all or substantially all of its assets or stock or as part of a merger.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Changes and amendments</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            We reserve the right to modify this Agreement or its terms relating to the Mobile Application and Services at any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Mobile Application and Services after any such changes shall constitute your consent to such changes.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Acceptance of these terms</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Mobile Application and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Mobile Application and Services.
                        </p>
                        <h3 className="mt-6 text-xl font-semibold">Contacting us</h3>
                        <p className="mt-4 text-lg leading-relaxed text-gray-600">
                            If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to <a className="text-green" href="mailto:legal@travlee.uk">legal@travlee.uk</a>
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  )
}
import React, { useEffect, useState } from "react";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [navBarTransparent, setNavbarTransparent] = useState(true)

  const handleScroll = (event) => {
    if (window.scrollY > 100)
      setNavbarTransparent(false)
    else
      setNavbarTransparent(true)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <nav
        className={
          (navBarTransparent
            ? "top-0 fixed z-50 w-full"
            : "top-0 fixed z-50 w-full shadow-lg bg-white shadow-lg") +
          " flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
        }
        style={{ transition: "all .4s ease" }}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <p
              className={
                (navBarTransparent
                  ? "lg:text-white lg:hover:text-green text-gray-800"
                  : "text-gray-800 hover:text-green") +
                " text-md font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase cursor-pointer"
              }
              onClick={() => props.navItemClicked("home", 0)}
            >
              Travlee
            </p>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <p
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-green text-gray-800"
                      : "text-gray-800 hover:text-green") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  }
                  onClick={() => props.navItemClicked("about", 50)}
                >
                  About
                </p>
              </li>
              <li className="flex items-center">
                <p
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-green text-gray-800"
                      : "text-gray-800 hover:text-green") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  }
                  onClick={() => props.navItemClicked("tree", 20)}
                >
                  Tree Planting
                </p>
              </li>
              <li className="flex items-center">
                <p
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-green text-gray-800"
                      : "text-gray-800 hover:text-green") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  }
                  onClick={() => props.navItemClicked("partners", 20)}
                >
                  Partners
                </p>
              </li>
              <li className="flex items-center">
                <p
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-green text-gray-800"
                      : "text-gray-800 hover:text-green") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  }
                  onClick={() => props.navItemClicked("contact", 10)}
                >
                  Contact
                </p>
              </li>
              <li className="flex items-center">
                <a
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-green text-gray-800"
                      : "text-gray-800 hover:text-green") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  }
                  href="https://medium.com/@travlee_uk"
                  target="_blank" rel="noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
            {/* <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  href="#pablo"
                >
                  <i
                    className={
                      (navBarTransparent
                        ? "lg:text-gray-300 text-gray-500"
                        : "text-gray-500") +
                      " fab fa-facebook text-lg leading-lg "
                    }
                  />
                  <span className="inline-block ml-2">For Business</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  href="#pablo"
                >
                  <i
                    className={
                      (navBarTransparent
                        ? "lg:text-gray-300 text-gray-500"
                        : "text-gray-500") +
                      " fab fa-twitter text-lg leading-lg "
                    }
                  />
                  <span className="lg:hidden inline-block ml-2">Tweet</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className={
                    (navBarTransparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  href="#pablo"
                >
                  <i
                    className={
                      (navBarTransparent
                        ? "lg:text-gray-300 text-gray-500"
                        : "text-gray-500") +
                      " fab fa-github text-lg leading-lg "
                    }
                  />
                  <span className="lg:hidden inline-block ml-2">Star</span>
                </a>
              </li>
              <li className="flex items-center">
                <button
                  className={
                    (props.transparent
                      ? "bg-white text-gray-800 active:bg-gray-100"
                      : "bg-pink-500 text-white active:bg-pink-600") +
                    " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  }
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> Download
                </button>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </>
  );
}
